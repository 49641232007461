<template>
	<div>


		<div style="padding:0px">
			<div class="infotable" v-if="StuInfo">

				<div colspan="4" class="headerbar">
					<span></span> 基础信息
				</div>

				<div>
					<div class="tdheadersml">学号：</div>
					<div>{{ StuInfo.stu_code }}</div>
				</div>
				<div>
					<div class="tdheadersml">姓名：</div>
					<div>{{ StuInfo.stu_name }}</div>
				</div>
				<div>
					<div class="tdheadersml">曾用名：</div>
					<div>{{ StuInfo.old_name }}</div>
				</div>
				<div>
					<div class="tdheadersml">身份证号：</div>
					<div>{{ StuInfo.idcard_encrypt }}</div>
				</div>
				<div>
					<div class="tdheadersml">出生年月：</div>
					<div>{{ StuInfo.birthday }}</div>
				</div>
				<div>
					<div class="tdheadersml">性别：</div>
					<div>{{ StuInfo.gender == 1 ? '男' : '女' }}</div>
				</div>
				<div>
					<div class="tdheadersml">籍贯：</div>
					<div>{{ StuInfo.jiguan }}</div>
				</div>
				<div>
					<div class="tdheadersml">政治面貌：</div>
					<div>{{ StuInfo.zzmm }}</div>
				</div>
				<div>
					<div class="tdheadersml">手机号码：</div>
					<div>{{ StuInfo.phone }}</div>
				</div>
				<div>
					<div class="tdheadersml">父亲电话：</div>
					<div>{{ StuInfo.jhr1phone }}</div>
				</div>
				<div>
					<div class="tdheadersml">母亲电话：</div>
					<div>{{ StuInfo.jhr2phone }}</div>
				</div>
				<div>
					<div class="tdheadersml">所在地区：</div>
					<div>{{ StuInfo.suozaidi }}</div>
				</div>
				<div>
					<div class="tdheadersml">详细地址：</div>
					<div colspan="3">{{ StuInfo.jtzz }}</div>
				</div>
				<div>
					<div class="tdheadersml">家庭邮编：</div>
					<div>{{ StuInfo.zip_code }}</div>
				</div>
				<div>
					<div class="tdheadersml">微信号：</div>
					<div>{{ StuInfo.weixin }}</div>
				</div>
				<div>
					<div class="tdheadersml">电子邮箱：</div>
					<div>{{ StuInfo.dzyx }}</div>
				</div>
				<div>

					<div class="tdheadersml">健康状况：</div>
					<div>{{ StuInfo.health }}</div>
				</div>
				<div>
					<div class="tdheadersml">身高：</div>
					<div>{{ StuInfo.height }}</div>
				</div>
				<div>
					<div class="tdheadersml">体重：</div>
					<div>{{ StuInfo.weight }}</div>
				</div>
				<div>
					<div class="tdheadersml">一寸照片：</div>
					<div colspan="3">
						<el-image v-if="StuInfo.photo" style="width:100px" :src="StuInfo.photo" fit="cover"
							:preview-src-list="[StuInfo.photo]"></el-image>
						<div v-else>未上传</div>
					</div>
				</div>


				<div colspan="4" class="headerbar">
					<span></span> 学籍信息
				</div>

				<div>
					<div class="tdheadersml">所属学校：</div>
					<div>{{ StuInfo.platform }}</div>
				</div>
				<div>
					<div class="tdheadersml">学校代码：</div>
					<div>{{ StuInfo.platform_code }}</div>
				</div>
				<div>
					<div class="tdheadersml">所属学院：</div>
					<div>{{ StuInfo.xueyuan }}</div>
				</div>
				<div>
					<div class="tdheadersml">学校专业：</div>
					<div>{{ StuInfo.class_info.zy_name }}</div>
				</div>
				<div>
					<div class="tdheadersml">专业代码：</div>
					<div>{{ StuInfo.class_info.zy_code }}</div>
				</div>
				<div>
					<div class="tdheadersml">专业方向：</div>
					<div>{{ StuInfo.class_info.zy_type }}</div>
				</div>
				<div>
					<div class="tdheadersml">学制：</div>
					<div>{{ StuInfo.class_info.zy_xingzhi }}</div>
				</div>
				<div>
					<div class="tdheadersml">学期：</div>
					<div>第二学期</div>
				</div>
				<div>
					<div class="tdheadersml">所在班级：</div>
					<div>{{ StuInfo.class_name }}</div>
				</div>
				<div>
					<div class="tdheadersml">入学时间：</div>
					<div>{{ StuInfo.ctime }}</div>
				</div>
				<div>
					<div class="tdheadersml">毕业时间：</div>
					<div>{{ StuInfo.graduate }}</div>
				</div>
				<div>
					<div class="tdheadersml">学习形式：</div>
					<div>{{ StuInfo.learn }}</div>
				</div>
				<div>
					<div class="tdheadersml">委培单位：</div>
					<div>{{ StuInfo.commission }}</div>
				</div>
				<div>
					<div class="tdheadersml">生源地：</div>
					<div>{{ StuInfo.place_of_origin }}</div>
				</div>

				<div colspan="4" class="headerbar">
					<span></span> 在校表现
				</div>


				<div>
					<div class="tdheadersml">专业主要课程：</div>
					<div colspan="3">{{ StuInfo.course }}</div>

				</div>
				<div>
					<div class="tdheadersml">担任班干部情况：</div>
					<div colspan="3">{{ StuInfo.duties }}</div>

				</div>
				<div>
					<div class="tdheadersml">奖惩情况：</div>
					<div colspan="3">{{ StuInfo.awards }}</div>

				</div>
				<div>
					<div class="tdheadersml">专业技能：</div>
					<div colspan="3">
						<div v-for="(item, idx) in StuInfo.skill_json" :key="idx">{{ item }}</div>

					</div>

				</div>
				<div>
					<div class="tdheadersml">专业证书：</div>
					<div colspan="3">
						<div v-for="(item, idx) in StuInfo.skill_img" :key="idx"
							style="display: inline-block; vertical-align: top; margin-left: 10px;">
							<div>{{ item.name }}</div>

							<el-image v-if="item.file" style="width:100px" :src="item.file" fit="cover"
								:preview-src-list="[item.file]"></el-image>

						</div>

					</div>

				</div>
				<div>
					<div class="tdheadersml">技能大赛最高奖项：</div>
					<div colspan="3">{{ StuInfo.skills_competition }}</div>

				</div>


				<div>
					<div class="tdheadersml">外语种类：</div>
					<div>{{ StuInfo.efl_type }}</div>
				</div>
				<div>
					<div class="tdheadersml">熟练度：</div>
					<div>{{ StuInfo.efl_proficiency }}</div>
				</div>
				<div>
					<div class="tdheadersml">计算机水平：</div>
					<div>{{ StuInfo.computer_level }}</div>
				</div>

				<div>
					<div class="tdheadersml">特长爱好：</div>
					<div colspan="3">{{ StuInfo.specialty }}</div>

				</div>
				<div>
					<div class="tdheadersml">个人小结：</div>
					<div colspan="3">{{ StuInfo.summary }}</div>

				</div>

				<div colspan="4" class="headerbar">
					<span></span> 实习信息
				</div>

				<div>
					<div class="tdheadersml">实习去向：</div>
					<div colspan="3"><span v-for="(item, i) in sxqx" v-if="sxInfo.sxqx == item.param_value">{{
						item.param_desc
					}}</span></div>

				</div>
				<div>
					<div class="tdheadersml">实习企业：</div>
					<div colspan="3">{{ sxInfo.firm_name }}</div>

				</div>
				<div>
					<div class="tdheadersml">企业联系人：</div>
					<div colspan="3">{{ sxInfo.firm_content_name }}</div>

				</div>
				<div>
					<div class="tdheadersml">联系电话：</div>
					<div colspan="3">{{ sxInfo.firm_content_tel }}</div>

				</div>
				<div>
					<div class="tdheadersml">部门：</div>
					<div colspan="3">{{ sxInfo.department }}</div>

				</div>
				<div>
					<div class="tdheadersml">岗位：</div>
					<div colspan="3">{{ sxInfo.job }}</div>

				</div>







				<div v-if="false">
					<div colspan="4" class="headerbar">
						<span></span> 就业名册
					</div>





					<div v-if="jiuye">
						<div class="tdheadersml">毕业去向：</div>
						<div>{{ jiuye.emp_survey_name }}</div>


					</div>
					<div v-if="jiuye">
						<div class="tdheadersml">单位：</div>
						<div>{{ jiuye.enter_name || jiuye.school_name }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">证明文件：</div>
						<div colspan="3">

							<div v-for="(item, idx) in jiuye.files" :key="idx"
								style="display: inline-block; vertical-align: top; margin-left: 10px;">
								<el-image v-if="item.NEWURL" style="width:100px" :src="item.NEWURL" fit="cover"
									:preview-src-list="[item.NEWURL]"></el-image>

							</div>

						</div>



					</div>

					<div v-if="jiuyeMore">
						<div class="tdheadersml">单位性质：</div>
						<div>{{ jiuye.enterprise_type_name }}</div>


					</div>

					<div v-if="jiuyeMore">

						<div class="tdheadersml">是否对口：</div>
						<div>{{ jiuye.counterpart == 1 ? '对口' : '不对口' }}</div>

					</div>

					<div v-if="jiuyeMore">
						<div class="tdheadersml">部门：</div>
						<div>{{ jiuyeMore.dept_name }}</div>

					</div>

					<div v-if="jiuyeMore">
						<div class="tdheadersml">岗位：</div>
						<div>{{ jiuyeMore.job_name }}</div>

					</div>

					<div v-if="jiuyeMore2">
						<div class="tdheadersml">专业类别：</div>
						<div>{{ jiuye.major_type }}</div>
					</div>

					<div v-if="jiuyeMore2">
						<div class="tdheadersml">企业类型：</div>
						<div>{{ jiuyeMore2.companyOrgType }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">产业类型：</div>
						<div>{{ jiuye.industry_type_name }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">地域：</div>
						<div>{{ jiuye.area_id_name }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">地点：</div>
						<div>{{ jiuye.location_name }}</div>

					</div>
					<div v-if="jiuye">
						<div class="tdheadersml">渠道：</div>
						<div>{{ jiuye.channel_name }}</div>

					</div>
					<div v-if="jiuye">
						<div class="tdheadersml">合同签订情况：</div>
						<div>{{ jiuye.contract_name }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">起薪情况：</div>
						<div>{{ jiuye.salary_num }}</div>

					</div>

					<div v-if="jiuye">
						<div class="tdheadersml">社会保险情况：</div>
						<div>{{ shebaoOptions[jiuye.social_security] }}</div>

					</div>
					<div v-if="jiuye">
						<div class="tdheadersml">资格证书情况：</div>
						<div>{{ jiuye.certificate == 1 ? '取得' : '未取得' }}</div>

					</div>
					<div v-if="jiuye">
						<div class="tdheadersml">就业满意度：</div>
						<div>

							<span v-if="jiuye.evaluation == 4">非常满意</span>
							<span v-if="jiuye.evaluation == 3">满意</span>
							<span v-if="jiuye.evaluation == 2">比较满意</span>
							<span v-if="jiuye.evaluation == 1">不满意</span>
							<span v-if="jiuye.evaluation == 0">无法评估</span>
						</div>



					</div>

				</div>


			</div>

			<div style="height:200px"></div>
		</div>


	</div>
</template>

<script>
export default {
	name: "xxcj",
	data() {
		return {
			isShowModal: true,
			StuInfo: null,
			jiuye: null,
			jiuyeMore: null,
			jiuyeMore2: null,
			shebaoOptions: ["没有社保", "五险一金", "三险一金", "五险", "三险"],
			sxqx: [],
			sxInfo: null
		}
	},
	mounted() {
		this.stuid = this.$Request("stuid")
		document.title = "信息采集详细信息"

		this.getList()
		this.getParams()


	},
	methods: {
		getList() {

			this.$http.post("/api/t_sch_students_form", {
				stu_id: this.stuid
			}).then(res => {
				if (res.data.course) {
					try {
						res.data.course = JSON.parse(res.data.course).join(",")
					} catch (error) {

					}

				}
				if (res.data.duties) {
					try {
						res.data.duties = JSON.parse(res.data.duties).join("。")

					} catch (error) {

					}
				}
				if (res.data.awards) {
					try {
						res.data.awards = JSON.parse(res.data.awards).join("。")

					} catch (error) {

					}
				}
				if (res.data.skill_json) {
					try {
						res.data.skill_json = JSON.parse(res.data.skill_json)

					} catch (error) {

					}
				}
				if (res.data.skill_img) {
					try {
						res.data.skill_img = JSON.parse(res.data.skill_img)

					} catch (error) {

					}
				}
				if (res.data.skills_competition) {
					try {
						res.data.skills_competition = JSON.parse(res.data.skills_competition).join("。")

					} catch (error) {

					}
				}


				this.$http.post("/api/t_sch_students_relate", {
					stu_id: this.stuid
				}).then(res2 => {
					for (let k in res2.data) {
						res.data[k] = res2.data[k]
					}
					this.StuInfo = res.data
				})


				this.$http.post("/api/jydc_info_by_stu_id", {
					stu_id: this.stuid
				}).then(res2 => {
					if (res2.data.files) {
						res2.data.files = JSON.parse(res2.data.files)
					}
					this.jiuye = res2.data
				})

				this.$http.post("/api/find_info_by_stu_id", {
					stu_id: this.stuid
				}).then(res2 => {
					if (res2.data.files) {
						res2.data.files = JSON.parse(res2.data.files)
					}
					this.jiuyeMore = res2.data

					this.$http.post("/api/tyc_search", {
						enter_name: res2.data.enter_name
					}).then(res3 => {

						this.jiuyeMore2 = res3.data
					})


				})



				this.$http.post("/api/t_sch_stu_enter_from", { id: res.data.stu_enter_id }).then(sx => {
					this.sxInfo = sx.data
				})















			})
		},
		getParams() {
			this.$http.post("/api/sys_param_list", {
				param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
				used: 0
			}).then(res => {
				let sxqx = [];
				for (let item of res.data) {
					if (item.param_type == "SHIXI_STATUS") {
						sxqx.push(item)
					}
				}
				this.sxqx = sxqx
			})
		},
		closeDialog() {
			this.$$parent(this, "showXxcj", false)
		}


	},
}
</script>

<style scoped>
.tdheadersml {
	display: inline-block;
	background-color: rgba(0, 0, 0, 0);
	color: #606266;
	text-align: left;
}

.infotable>div {
	border-bottom: 1px solid #eee;
	padding: 17px 20px;
}

.infotable>div>div:nth-child(2) {
	display: inline-block;
	width: calc(100% - 100px);
	vertical-align: top;
	color: #000000;
}


.headerbar {
	font-weight: bolder;
	color: #1289ff;
	padding: 17px 16px !important;
	background-color: #f2f4f8;
}

.headerbar span {
	display: inline-block;
	height: 18px;
	width: 8px;
	background-color: #1289ff;
	vertical-align: middle;
	transform: translateY(-2px);
	border-radius: 5px;
}
</style>

